
<template>
    <van-popup  
      class="face-box"
      :value="value" 
      @input="val => this.$emit('input', val)"  
      position="bottom"
      :get-container="getContainer"
    >
    <!--- 头部按钮 区域-->
    <face-header @close="closeWin" @switchCamera="switchCamera"></face-header>

    <!--- 摄像头视频 区域-->
    <face-video :show="value" :isMeeting="isMeeting" @close="closeWin" @load-finish="showFaceSet = true"></face-video>

    <!--- 底部设置面板 区域 -->
    <face-set :show="value" v-show="enableFace && showFaceSet" ref="faceSet"></face-set>
    
    </van-popup>
</template>

<script>

import Header from "./header.vue"
import Video from "./video.vue"
import Set from "./set.vue"

export default {
  data() {
    return {
      showFaceSet: false
    }
  },

  props: {
    isMeeting: { default : false },
    value: {
      type: Boolean,
      default: false
    }
  },

  components: {
    "face-header": Header,
    "face-video": Video,
    "face-set": Set
  },

  computed: {
    enableFace() {
      return this.$store.state.meet.enableFace
    }
  },
  mounted() {
    this.$eventBus.$on('close-face-panel', () => {
      this.closeWin()
    })
  },

  methods: {
    closeWin() {
      //移除安卓手机小视频弹窗
      const videos = document.getElementsByTagName('video')
      for (let index = 0; index < videos.length; index++) {
         if (videos[index].getAttribute('crossorigin') === "anonymous") {
           document.body.removeChild(videos[index])
         } 
      }

      this.$emit("close")
    },

    switchCamera() {
      this.$eventBus.$emit('switch-camera-preview')
    },

    getContainer() {
      return document.querySelector('#app')
    }
  }
};
</script>

<style lang="less" scoped>
.face-box {
  overflow: hidden;
  height: 100%;
  width: 100%;

  will-change: transform;
}
</style>