<template>
  <div
    id="video-wrapper"
    class="video-wrapper"
  >
    <!-- 媒体组件 -->
      <video
        autoplay
        webkit-playsinline
        playsinline
        muted
        ref="faceVideo"
      ></video>

      <van-loading v-if="status == 'loading'" />
  </div>
</template>

<script>
import { loganLog } from "@/utils/log"
import { ERR_CAMERA_NO_GRANT } from "@/constant/index"
import { isIPhone } from "@/utils/mobile"

export default {
   data() {
    return {
      status: 'loading',
      cameras: [],
      currentCamera: null,
    }
  },

  props: {
    show: { default: false }, // 是否展示设置弹窗
    isMeeting: { default : false },
  },

  watch: {
    show: {
      handler(value) {
        if (value) {
         
          this.status = 'loading' 
          setTimeout(() => { 
             this.initCameras()
          }, 1000)
        } else {
          const element = this.$refs.faceVideo
          element.srcObject = null
        }
      },
      immediate: true
    }
  },

  computed: {
    localUser() {
      return this.$store.getters["member/getUser"](this.$configs.peerId);
    },
  },

  mounted(){
    this.$eventBus.$on('switch-camera-preview', this.switchCameraPreview)

    this.$eventBus.$on('face-video-clone', this.cloneLocalVideo)
  },

  methods: {
    async initCameras() {
      try {
        console.error('初始化预览执行了')
        this.status = 'loading'

        this.cameras = await this.sdk.settings.getCameraDevicesList();

        console.error(`[web sdk] cameras: ${JSON.stringify(this.cameras)}`);
        loganLog(`[web sdk] cameras: ${JSON.stringify(this.cameras)}`)

        await this.startLocalPreview(this.cameras[0]?.deviceId) 
      } catch (error) {
        console.error(error)
        this.status = 'finish'
        loganLog(error,'美颜预览摄像头初始化失败')
      }
    },

    async startLocalPreview(deviceId) {
       this.status = 'loading'

       //const element = document.getElementById('v-face-setting')
       const element = this.$refs.faceVideo
       console.error(element)

       //开启本地视频预览
       const _startLocalPreview = async () => {
        console.error('当前应用摄像头',deviceId)
        let width = 640, height = 360

        const isIOS = isIPhone()

        console.error(`操作系统是否苹果：${isIOS}`)
        console.error(`startLocalPreviewOnly 分辨率：${width}*${height}`)

        //切换前 关掉之前track
        await this.stopCameraPreview()

        await this.sdk.rtc.startLocalPreviewOnly(width, height, deviceId, element)
        .then(async res => {
          this.status = 'finish'
          this.$emit('load-finish') //加载完成 展示下方设置面板

          console.error('startLocalPreviewOnly调用完成')

          this.currentCamera = deviceId
          this.cameras = await this.sdk.settings.getCameraDevicesList();
        })
        .catch(error => {
          console.error(error)
          loganLog(error,'美颜预览摄像头打开失败')
          //摄像头权限校验
          this.status = 'finish'

          if (error && error.errorCode == ERR_CAMERA_NO_GRANT) {
            this.noCameraGrant()
          }
        })
       }
       

       element.addEventListener('loadedmetadata', async () => {
          loganLog('startLocalPreview() | 视频预览关键帧装载完毕！ loadedmetadata')
          try {
            await element.play();
          } catch (error) {
              console.error('loadedmetadata Play Error:',error,element)
          }
        })

       //监听pause事件  手机通知下拉等会触发
       element.addEventListener('pause', () => {
          this.status = 'loading'
          setTimeout(async () => {
            console.error('触发美颜预览video paused事件...')
            try {
              //会中用户已经开启视频
              if (this.isMeeting 
                 && this.localUser 
                 && this.localUser.isUseShiPin) {
                console.error('触发会中预览video paused事件...')
                return 
              }

              await _startLocalPreview()
              this.status = 'finish'
            } catch (error) {
              console.error('预览video pause事件处理发生异常',error)
              this.status = 'finish'
            }
          }, 500)
       })
       
       //会中用户已经开启视频
       if (this.isMeeting 
           && this.localUser 
           && this.localUser.isUseShiPin) {
         await this.cloneLocalVideo()
         return
       }

      //  this.$nextTick(_startLocalPreview) 
      await _startLocalPreview()
    }, 
    
    //克隆会中本地用户视频流
    async cloneLocalVideo() {
        try {
          this.status = 'loading'
          // const element = document.getElementById('v-face-setting')
          const element = this.$refs.faceVideo
          console.error(element)
          console.error('预览时，克隆会中本地用户视频流', element)
          await this.sdk.rtc.cloneLocalVideo(element)
          //await element.play()
          console.error('会中用户已经开启视频')
          this.status = 'finish'
          this.$emit('load-finish') //加载完成 展示下方设置面板
        } catch (error) {
          console.error('会中预览 cloneLocalVideo 出错', error)
          this.status = 'finish'
        }
    },
    

    noCameraGrant() {
      this.$dialog.alert({
        message: '打开摄像头失败，无法获取权限，请关闭小程序重新打开后再设置',
        confirmButtonText: '返回',
        confirmButtonColor: '#FF4D4F'
      })
      .then(()=> this.$emit("close"))
    },

    async stopCameraPreview() {
      try {
        console.error('执行停止美颜预览了')
        await this.sdk.rtc.stopLocalPreviewOnly()

      } catch (error) {
        console.error('关闭视频预览失败',error)
      }
    },

    async switchCameraPreview() {
      console.error('预览切换摄像头执行了')

      //会中用户已经开启视频
      if (this.isMeeting 
           && this.localUser 
           && this.localUser.isUseShiPin) { 
        this.status = 'loading'  
        this.$eventBus.$emit('switch-camera', async () => {
          console.error('会中切换摄像头执行了')
          await this.cloneLocalVideo()
          this.status = 'finish'
        })
        return
      }

      const cameras = this.cameras;
      console.error(`[switchCameraPreview()] cameras: ${JSON.stringify(cameras)}`)
      let other = null
      if(cameras.length < 2) {
        throw new Error('switchCamera no more camera')
      }

      if (!this.currentCamera) {
        this.currentCamera = cameras[0].deviceId;// 取不到的话，取第一个
      }

      other = cameras.filter(item => item.deviceId != this.currentCamera)[0];
      // 如果大于 2 则去
      if(cameras.length > 1) {
        if (this.currentCamera == 'environment') {
          other = {
            deviceId: cameras[0].deviceId,
          }
        } else {
          other = {
            deviceId: 'environment',
          }
        }
      }

      try {
        loganLog(`switchCamera: currentCamera ${this.currentCamera}, other ${other.deviceId}`,'切换美颜预览摄像头')

        await this.startLocalPreview(other.deviceId)
        console.error(`[switchCameraPreview()] currentCamera: ${this.currentCamera}, other ${other.deviceId}`)

      } catch (error) {
        console.error('切换美颜预览摄像头失败', error)
        loganLog(error,'切换美颜预览摄像头失败')
        throw new Error(error)
      }
    }
  },

}
</script>

<style lang="less" scoped>
 div {
    box-sizing: border-box;
  }
 .video-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;

    background: #222;

    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: #222;
      transform: rotateY(180deg);
      -webkit-transform:rotateY(180deg); /* Safari and Chrome */
      -moz-transform:rotateY(180deg); /* Firefox */
    }

     .van-loading {
      position: absolute;
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }
 }
</style>
