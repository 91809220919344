<template>
  <div
    class="set-area"
  >

  <div class="face-slider" v-if="faceLevel === 3 && faceType > -1">
     <van-slider 
      v-model="faceValue"
      :min="0" 
      :step="1"
      active-color="#1AB370"
      :max="faceMaxValue" 
      >
      <template #button>
        <div class="cus-slider-button"></div>
      </template>
    </van-slider>
    <span>{{faceValue}}</span>
  </div>

  <div class="face-level" :class="{'no-custom': faceLevel < 3 }">
     <div class="btn" 
      :class="{'selected': idx === faceLevel }" 
       v-for="(level,idx) in levelsOpt" 
       :key="idx"
       @click="debounceHandle(onLevelChange.bind(this, idx))"
       >
       {{level}}
     </div>
  </div>

  <div class="face-type" v-if="faceLevel === 3">
     <div 
       class="block" 
       :class="{'selected': type.value === faceType }" 
       v-for="(type,idx) in typesOpt" 
       @click="debounceHandle(onTypeChange.bind(this, type.value))"
       :key="idx">
       <my-icon
          v-if="type.value === -1"
          fontSize="88"
           :imgUrl="`face/${type.key}/nocheck_${recoverEnable ? 'use' : 'disable'}.png`"
        ></my-icon>
      <my-icon
          v-else
          fontSize="88"
           :imgUrl="`face/${type.key}/${type.value === faceType ? 'check' : 'nocheck'}_${faceNumbers[type.value] ? 'nouse' : 'use'}.png`"
        ></my-icon>
      <span>{{type.text}}</span>
     </div>
  </div>
  </div>
</template>

<script>

import { debounce } from 'lodash';
import { MEDIA_THROTLE_TIME } from "@/constant/index";


export default {
   data() {
    return {
      faceMaxValue: 100,
      faceValue: 0,
      levelsOpt: ['轻度美颜','中度美颜','重度美颜','自定义'],
      typesOpt: [
        {
          text: '恢复',
          key:'recover',
          value: -1
        }, 
        {
          text: '美白',
          key:'white',
          value: 0
        }, 
        {
          text: '磨皮',
          key:'skin',
          value: 1
        }, 
        {
          text: '瘦脸',
          key:'thin',
          value: 2
        }, 
        {
          text: '削脸',
          key:'pare',
          value: 3
        },
        {
          text: '大眼',
          key:'eye',
          value: 4
        },
        {
          text: '下巴',
          key:'chin',
          value: 5
        }
      ]
    }
  },

  props: {
    show: { default: false }, // 是否展示设置弹窗
  },

  watch: {
    show: {
      handler(value) {
        if (value && this.enableFace) {
         //从上次操作记忆中加载 设置数据
         this.$store.commit("face/applyFaceStateFromCache", true)
        }
      },
      immediate: true
    },

    faceValue(value) {
      this.$store.commit('face/updateGlobalFaceState', {
        number: value
      })
    },

    faceNumber: {
      immediate: true,
      handler(value) {
        this.faceValue = value
        this.onSliderChange(value)
      }
    },

    async enableFace(value) {
      //关闭美颜 美颜失效，打开美颜应用上次的值
      if (value) {
        await this.$store.commit("face/applyFaceStateFromCache", true)
      } else {
        //SDK关闭美颜 不改变数值
        await this.$store.commit("face/setBeautyLevel", -1)
      }
    }
  },

  computed: {
    enableFace() {
      return this.$store.state.meet.enableFace
    },

    faceNumber() {
      return this.$store.state.face.number
    },

    faceLevel() {
      return this.$store.state.face.level
    },

    faceType() {
      return this.$store.state.face.type
    },

    recoverEnable() {
      return this.faceNumbers.some(v => v > 0)
    },

    faceNumbers() {
      return this.$store.state.face.typeNumbers
    }
  },

  mounted() {
  },

  methods: {
    //限制按钮点击频率
    debounceHandle: debounce(fn => fn && fn(), MEDIA_THROTLE_TIME, { leading: true, trailing: false }),

    
    onSliderChange: debounce(async function(value) {
      if (this.faceType > -1) {
        console.error(value)
        const number = Number(value || 0)
        const type = this.faceType
        await this.$store.commit("face/setOneTypeNumber", {
           type,
           number
        })
      }
    }, 200),

    async onLevelChange(level) {
      this.$store.commit('face/updateGlobalFaceState', {
        level
      })

      await this.$store.commit("face/setFaceCacheInfo") //更新存储

      if (this.faceLevel === 3) { //自定义 优先应用缓存 缓存没有 关闭美颜
        await this.$store.commit("face/applyFaceStateFromCache", false)
      } else {
        await this.$store.commit("face/setBeautyLevel", level)//应用SDK 等级
      }
    },
    
    async onTypeChange(type) {
      if (!this.recoverEnable && type === -1)  return

      if (type === -1) { //恢复设置 初始化
          this.$dialog.confirm({
            title: this.$t('login.tips'),
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            message: '确定全部恢复默认效果吗？'
          })
          .then(async () => {

            this.$store.commit('face/updateGlobalFaceState', {
              type: 0,
              number: 0,
              typeNumbers: [0, 0, 0, 0, 0, 0] 
            })

            await this.$store.commit("face/setFaceCacheInfo") //更新存储

            await this.$store.commit("face/setBeautyLevel", -1) //应用SDK 关闭美颜
          })
          .catch(()=> {})
      } else {
        const  number = this.faceNumbers[type] || 0
        await this.$store.commit('face/setSingleBeautyCustom', {
          type,
          number 
        })
      }
    }

  },

}
</script>

<style lang="less" scoped>
  .flex(@align:center, @justify:center) { 
    display: flex;
    align-items: @align;
    justify-content: @justify;
  }

  .set-area {
    overflow: hidden;
    width: 100%;
    position: absolute;
    bottom: 0;
    background:#fff;
    user-select: none;
    -webkit-user-select: none;
  }

  .cus-slider-button{
    width: 34px;
    height: 34px;
    background: #FFFFFF;
    border: 2px solid #1AB370;
    border-radius: 100%;
  }

  .face-slider {
     width: 568px;
     height:24px;
     margin:0 auto;
     margin-top: 24px;
     .flex(center, flex-start);

     span {
      display:inline-block;
      margin-left: 25px;     
      font-size: 22px;
      font-weight: 400;
      color: #999999;
     }
  }

  .face-level {
    width: 688px;
    height: 72px;
    margin:0 auto;
    margin-top: 32px;
    .flex(center,space-between);
     .btn {
        width: 160px;
        height: 72px;
        text-align: center;
        line-height: 72px;
        border-radius: 8px;
        background: #fff;
        border: 1px solid #E5E5E5;
        font-size: 24px;
        font-weight: 400;
        color: #000000;
     }
     .btn.selected {
        background: #1AB370;
        border: 1px solid #1AB370;
        color: #fff;
     }
  }

  .face-level.no-custom {
    margin-bottom:64px;
  }

  .face-type {
    width: 100%;
    margin:0 auto;
    margin-top: 36px;
    margin-bottom: 34px;
    .flex(center,flex-start);
    flex-flow:row wrap;
    padding-left: 65px;
    .block {
      width: 88px;
      height: 169px;
      background: rgba(255, 255, 255, 0.1);
      margin-right: 48px;
      flex-direction: column;
      .flex(center,center);

      span {
        font-size: 24px;
        display: inline-block;
        font-weight: 400;
        color: #333333;
        line-height: 33px;
      }
    }

    img {
      width: 88px;
      height: 88px;
      margin-bottom: 8px;
    }
  }

  ::v-deep .van-slider {
    height: 8px;
    border-radius: 4px;
  }
</style>
