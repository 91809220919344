<template>
  <div class="face-header">
    <div class="left">
      <div
        class="icon-wrapper mg-r-32"
        @click="handleBack"
      >
        <my-icon
          fontSize="48"
          imgUrl="icon-arrow-white.png"
        ></my-icon>
      </div>
      <div
        class="icon-wrapper"
        @click.stop="switchCamera"
      >
        <my-icon
          fontSize="48"
          iconName="icon-camera"
        ></my-icon>
      </div>
    </div>
    <div
      class="icon-wrapper"
      @click="switchFace"
    >
      <my-icon
        fontSize="48"
        :imgUrl="enableFace ? 'icon-closeface-white.png' : 'icon-face-white.png'"
      ></my-icon>
    </div>

  </div>
</template>

<script>

import { debounce } from 'lodash';
import { MEDIA_THROTLE_TIME } from "@/constant/index";
import { saveLocal } from '@/utils/tool'

export default {
   data() {
    return {
    }
  },

  props: {

  },

  computed: {
    enableFace () {
      return this.$store.state.meet.enableFace
    }
  },

  methods: {
    
    switchCamera: debounce(function() {
      
      this.$emit('switchCamera')
      console.log('debounce');
    }, MEDIA_THROTLE_TIME,{ leading: true, trailing: false}),

    switchFace() { 
      const enableStatus = !this.enableFace
      console.error('enableStatus',enableStatus)
      this.$store.commit("meet/updateGlobalMeetState", {
        enableFace: enableStatus
      })
      saveLocal("isUseMeiYan", enableStatus) //记忆选择
    }, 

    handleBack() {
      this.$emit("close")
    }

  },

}
</script>

<style lang="less" scoped>

  .face-header {
    padding: 32px 32px 0;
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    position: absolute;
    z-index: 2;
  }

  .left {
    display: flex;
  }
  .icon-wrapper {
    width: 72px;
    height: 72px;
    background: rgba(0, 0, 0, 0.45);
    border-radius: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mg-r-32 {
    margin-right: 32px;
  }

</style>
